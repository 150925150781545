import { __assign } from "tslib";
import { useState, useEffect } from 'react';
import Form from '../form';
export var useForm = function (config) {
    var _a = useState(false), defaultFormValuesLoading = _a[0], setDefaultFormValuesLoading = _a[1];
    var _b = useState({}), initialValues = _b[0], setInitialValues = _b[1];
    var defaultFormValues = config.defaultFormValues, form = config.form, submit = config.submit;
    var _c = useState({}), formValues = _c[0], setFormValues = _c[1];
    var _d = useState(false), formLoading = _d[0], setFormLoading = _d[1];
    var _e = useState(), formResult = _e[0], setFormResult = _e[1];
    var version = 3;
    // antd4
    if (Form['useForm']) {
        version = 4;
    }
    var formInstance = form;
    if (!form) {
        if (version === 4) {
            formInstance = Form['useForm']()[0];
        }
        else {
            throw new Error('"form" need in antd@3');
        }
    }
    var onFinish = function (formValue) {
        setFormValues(formValue);
        setFormLoading(true);
        return new Promise(function (resolve, reject) {
            if (version === 4) {
                formInstance
                    .validateFields()
                    .then(function () {
                    resolve(Promise.resolve(submit(formValue))
                        .then(function (data) {
                        setFormLoading(false);
                        setFormResult(data);
                        return data;
                    })
                        .catch(function (err) {
                        setFormLoading(false);
                        throw err;
                    }));
                })
                    .catch(function (validateErr) {
                    setFormLoading(false);
                    reject(validateErr);
                });
            }
            else {
                formInstance.validateFields(function (validateErr) {
                    if (validateErr) {
                        setFormLoading(false);
                        reject(validateErr);
                    }
                    else {
                        resolve(Promise.resolve(submit(formValue))
                            .then(function (data) {
                            setFormLoading(false);
                            setFormResult(data);
                            return data;
                        })
                            .catch(function (err) {
                            setFormLoading(false);
                            throw err;
                        }));
                    }
                });
            }
        });
    };
    useEffect(function () {
        var isUnMounted = false;
        if (!defaultFormValues) {
            return;
        }
        var value;
        if (typeof defaultFormValues === 'function') {
            setDefaultFormValuesLoading(true);
            value = defaultFormValues();
        }
        else {
            value = defaultFormValues;
        }
        Promise.resolve(value)
            .then(function (data) {
            if (!isUnMounted) {
                var obj_1 = __assign({}, data);
                Object.keys(data).forEach(function (name) {
                    obj_1[name] = formInstance.isFieldTouched(name)
                        ? formInstance.getFieldValue(name)
                        : data[name];
                });
                setDefaultFormValuesLoading(false);
                setInitialValues(data);
                formInstance.setFieldsValue(obj_1);
            }
        })
            .catch(function () {
            if (!isUnMounted) {
                setDefaultFormValuesLoading(false);
            }
        });
        return function () {
            isUnMounted = true;
        };
    }, []);
    var formProps = version === 4
        ? {
            form: formInstance,
            onFinish: onFinish,
            initialValues: initialValues,
        }
        : {
            onSubmit: function (e) {
                e.preventDefault();
                onFinish(formInstance.getFieldsValue(version === 4 ? true : undefined));
            },
        };
    return {
        form: formInstance,
        formProps: formProps,
        defaultFormValuesLoading: defaultFormValuesLoading,
        formValues: formValues,
        initialValues: initialValues,
        formResult: formResult,
        formLoading: formLoading,
        submit: function (values) {
            formInstance.setFieldsValue(values);
            return onFinish(formInstance.getFieldsValue(version === 4 ? true : undefined));
        },
    };
};
