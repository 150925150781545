"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _Input = _interopRequireDefault(require("./Input"));

var _Group = _interopRequireDefault(require("./Group"));

var _Search = _interopRequireDefault(require("./Search"));

var _TextArea = _interopRequireDefault(require("./TextArea"));

var _Password = _interopRequireDefault(require("./Password"));

var Input = _Input["default"];
Input.Group = _Group["default"];
Input.Search = _Search["default"];
Input.TextArea = _TextArea["default"];
Input.Password = _Password["default"];
var _default = Input;
exports["default"] = _default;