import { __assign, __asyncValues, __awaiter, __extends, __generator, __read } from "tslib";
import { AbortController } from "@aws-sdk/abort-controller";
import { CompleteMultipartUploadCommand, CreateMultipartUploadCommand, PutObjectCommand, PutObjectTaggingCommand, UploadPartCommand, } from "@aws-sdk/client-s3";
import { extendedEncodeURIComponent } from "@aws-sdk/smithy-client";
import { EventEmitter } from "events";
import { byteLength } from "./bytelength";
import { getChunk } from "./chunker";
var MIN_PART_SIZE = 1024 * 1024 * 5;
var Upload = (function (_super) {
    __extends(Upload, _super);
    function Upload(options) {
        var _this = _super.call(this) || this;
        _this.MAX_PARTS = 10000;
        _this.queueSize = 4;
        _this.partSize = MIN_PART_SIZE;
        _this.leavePartsOnError = false;
        _this.tags = [];
        _this.concurrentUploaders = [];
        _this.uploadedParts = [];
        _this.isMultiPart = true;
        _this.queueSize = options.queueSize || _this.queueSize;
        _this.partSize = options.partSize || _this.partSize;
        _this.leavePartsOnError = options.leavePartsOnError || _this.leavePartsOnError;
        _this.tags = options.tags || _this.tags;
        _this.client = options.client;
        _this.params = options.params;
        _this.__validateInput();
        _this.totalBytes = byteLength(_this.params.Body);
        _this.bytesUploadedSoFar = 0;
        _this.abortController = new AbortController();
        return _this;
    }
    Upload.prototype.abort = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.abortController.abort();
                return [2];
            });
        });
    };
    Upload.prototype.done = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, Promise.race([this.__doMultipartUpload(), this.__abortTimeout(this.abortController.signal)])];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    Upload.prototype.on = function (event, listener) {
        this.uploadEvent = event;
        return _super.prototype.on.call(this, event, listener);
    };
    Upload.prototype.__uploadUsingPut = function (dataPart) {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, putResult, endpoint, locationKey, locationBucket, Location, totalSize;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isMultiPart = false;
                        params = __assign(__assign({}, this.params), { Body: dataPart.data });
                        return [4, Promise.all([
                                this.client.send(new PutObjectCommand(params)),
                                this.client.config.endpoint(),
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), putResult = _a[0], endpoint = _a[1];
                        locationKey = this.params
                            .Key.split("/")
                            .map(function (segment) { return extendedEncodeURIComponent(segment); })
                            .join("/");
                        locationBucket = extendedEncodeURIComponent(this.params.Bucket);
                        Location = this.client.config.forcePathStyle
                            ? "".concat(endpoint.protocol, "//").concat(endpoint.hostname, "/").concat(locationBucket, "/").concat(locationKey)
                            : "".concat(endpoint.protocol, "//").concat(locationBucket, ".").concat(endpoint.hostname, "/").concat(locationKey);
                        this.singleUploadResult = __assign(__assign({}, putResult), { Bucket: this.params.Bucket, Key: this.params.Key, Location: Location });
                        totalSize = byteLength(dataPart.data);
                        this.__notifyProgress({
                            loaded: totalSize,
                            total: totalSize,
                            part: 1,
                            Key: this.params.Key,
                            Bucket: this.params.Bucket,
                        });
                        return [2];
                }
            });
        });
    };
    Upload.prototype.__createMultipartUpload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var createCommandParams, createMultipartUploadResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.createMultiPartPromise) {
                            createCommandParams = __assign(__assign({}, this.params), { Body: undefined });
                            this.createMultiPartPromise = this.client.send(new CreateMultipartUploadCommand(createCommandParams));
                        }
                        return [4, this.createMultiPartPromise];
                    case 1:
                        createMultipartUploadResult = _a.sent();
                        this.uploadId = createMultipartUploadResult.UploadId;
                        return [2];
                }
            });
        });
    };
    Upload.prototype.__doConcurrentUpload = function (dataFeeder) {
        var dataFeeder_1, dataFeeder_1_1;
        var e_1, _a;
        return __awaiter(this, void 0, void 0, function () {
            var dataPart, partResult, e_2, e_1_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 12, 13, 18]);
                        dataFeeder_1 = __asyncValues(dataFeeder);
                        _b.label = 1;
                    case 1: return [4, dataFeeder_1.next()];
                    case 2:
                        if (!(dataFeeder_1_1 = _b.sent(), !dataFeeder_1_1.done)) return [3, 11];
                        dataPart = dataFeeder_1_1.value;
                        if (this.uploadedParts.length > this.MAX_PARTS) {
                            throw new Error("Exceeded ".concat(this.MAX_PARTS, " as part of the upload to ").concat(this.params.Key, " and ").concat(this.params.Bucket, "."));
                        }
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 9, , 10]);
                        if (this.abortController.signal.aborted) {
                            return [2];
                        }
                        if (!(dataPart.partNumber === 1 && dataPart.lastPart)) return [3, 5];
                        return [4, this.__uploadUsingPut(dataPart)];
                    case 4: return [2, _b.sent()];
                    case 5:
                        if (!!this.uploadId) return [3, 7];
                        return [4, this.__createMultipartUpload()];
                    case 6:
                        _b.sent();
                        if (this.abortController.signal.aborted) {
                            return [2];
                        }
                        _b.label = 7;
                    case 7: return [4, this.client.send(new UploadPartCommand(__assign(__assign({}, this.params), { UploadId: this.uploadId, Body: dataPart.data, PartNumber: dataPart.partNumber })))];
                    case 8:
                        partResult = _b.sent();
                        if (this.abortController.signal.aborted) {
                            return [2];
                        }
                        this.uploadedParts.push(__assign(__assign(__assign(__assign({ PartNumber: dataPart.partNumber, ETag: partResult.ETag }, (partResult.ChecksumCRC32 && { ChecksumCRC32: partResult.ChecksumCRC32 })), (partResult.ChecksumCRC32C && { ChecksumCRC32C: partResult.ChecksumCRC32C })), (partResult.ChecksumSHA1 && { ChecksumSHA1: partResult.ChecksumSHA1 })), (partResult.ChecksumSHA256 && { ChecksumSHA256: partResult.ChecksumSHA256 })));
                        this.bytesUploadedSoFar += byteLength(dataPart.data);
                        this.__notifyProgress({
                            loaded: this.bytesUploadedSoFar,
                            total: this.totalBytes,
                            part: dataPart.partNumber,
                            Key: this.params.Key,
                            Bucket: this.params.Bucket,
                        });
                        return [3, 10];
                    case 9:
                        e_2 = _b.sent();
                        if (!this.uploadId) {
                            throw e_2;
                        }
                        if (this.leavePartsOnError) {
                            throw e_2;
                        }
                        return [3, 10];
                    case 10: return [3, 1];
                    case 11: return [3, 18];
                    case 12:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3, 18];
                    case 13:
                        _b.trys.push([13, , 16, 17]);
                        if (!(dataFeeder_1_1 && !dataFeeder_1_1.done && (_a = dataFeeder_1.return))) return [3, 15];
                        return [4, _a.call(dataFeeder_1)];
                    case 14:
                        _b.sent();
                        _b.label = 15;
                    case 15: return [3, 17];
                    case 16:
                        if (e_1) throw e_1.error;
                        return [7];
                    case 17: return [7];
                    case 18: return [2];
                }
            });
        });
    };
    Upload.prototype.__doMultipartUpload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dataFeeder, index, currentUpload, result, uploadCompleteParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataFeeder = getChunk(this.params.Body, this.partSize);
                        for (index = 0; index < this.queueSize; index++) {
                            currentUpload = this.__doConcurrentUpload(dataFeeder);
                            this.concurrentUploaders.push(currentUpload);
                        }
                        return [4, Promise.all(this.concurrentUploaders)];
                    case 1:
                        _a.sent();
                        if (this.abortController.signal.aborted) {
                            throw Object.assign(new Error("Upload aborted."), { name: "AbortError" });
                        }
                        if (!this.isMultiPart) return [3, 3];
                        this.uploadedParts.sort(function (a, b) { return a.PartNumber - b.PartNumber; });
                        uploadCompleteParams = __assign(__assign({}, this.params), { Body: undefined, UploadId: this.uploadId, MultipartUpload: {
                                Parts: this.uploadedParts,
                            } });
                        return [4, this.client.send(new CompleteMultipartUploadCommand(uploadCompleteParams))];
                    case 2:
                        result = _a.sent();
                        return [3, 4];
                    case 3:
                        result = this.singleUploadResult;
                        _a.label = 4;
                    case 4:
                        if (!this.tags.length) return [3, 6];
                        return [4, this.client.send(new PutObjectTaggingCommand(__assign(__assign({}, this.params), { Tagging: {
                                    TagSet: this.tags,
                                } })))];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2, result];
                }
            });
        });
    };
    Upload.prototype.__notifyProgress = function (progress) {
        if (this.uploadEvent) {
            this.emit(this.uploadEvent, progress);
        }
    };
    Upload.prototype.__abortTimeout = function (abortSignal) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, new Promise(function (resolve, reject) {
                        abortSignal.onabort = function () {
                            var abortError = new Error("Upload aborted.");
                            abortError.name = "AbortError";
                            reject(abortError);
                        };
                    })];
            });
        });
    };
    Upload.prototype.__validateInput = function () {
        if (!this.params) {
            throw new Error("InputError: Upload requires params to be passed to upload.");
        }
        if (!this.client) {
            throw new Error("InputError: Upload requires a AWS client to do uploads with.");
        }
        if (this.partSize < MIN_PART_SIZE) {
            throw new Error("EntityTooSmall: Your proposed upload partsize [".concat(this.partSize, "] is smaller than the minimum allowed size [").concat(MIN_PART_SIZE, "] (5MB)"));
        }
        if (this.queueSize < 1) {
            throw new Error("Queue size: Must have at least one uploading queue.");
        }
    };
    return Upload;
}(EventEmitter));
export { Upload };
