var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useMemo } from 'react';
import Input from 'antd/lib/input';
import IMask from 'imask';
export { IMask };
export var MaskedInput = React.forwardRef(function MaskedInput(props, antdRef) {
    var mask = props.mask, _maskOptions = props.maskOptions, _value = props.value, defaultValue = props.defaultValue, definitions = props.definitions, antdProps = __rest(props, ["mask", "maskOptions", "value", "defaultValue", "definitions"]);
    var innerRef = React.useRef(null);
    var maskOptions = useMemo(function () {
        return __assign({ mask: mask, definitions: __assign(__assign({ '0': /[0-9]/ }, _maskOptions === null || _maskOptions === void 0 ? void 0 : _maskOptions.definitions), definitions), lazy: false }, _maskOptions);
    }, [mask]);
    var placeholder = useMemo(function () {
        return IMask.createPipe(__assign(__assign({}, maskOptions), { lazy: false }))('');
    }, [maskOptions]);
    var imask = React.useRef(null);
    var propValue = (typeof _value === 'string' ? _value : defaultValue) || '';
    var lastValue = React.useRef(propValue);
    var _a = React.useState(propValue), value = _a[0], setValue = _a[1];
    var _onEvent = React.useCallback(function (ev, execOnChangeCallback) {
        var _a;
        if (execOnChangeCallback === void 0) { execOnChangeCallback = false; }
        var masked = imask.current;
        if (!masked)
            return;
        if (ev.target) {
            if (ev.target.value !== masked.value) {
                masked.value = ev.target.value;
                ev.target.value = masked.value;
                lastValue.current = masked.value;
            }
        }
        Object.assign(ev, {
            maskedValue: masked.value,
            unmaskedValue: masked.unmaskedValue,
        });
        masked.updateValue();
        setValue(lastValue.current);
        if (execOnChangeCallback) {
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, ev);
        }
    }, []);
    var _onAccept = React.useCallback(function (ev) {
        if (!(ev === null || ev === void 0 ? void 0 : ev.target))
            return;
        var input = innerRef.current;
        var masked = imask.current;
        if (!input || !masked)
            return;
        ev.target.value = masked.value;
        input.value = masked.value;
        lastValue.current = masked.value;
        _onEvent(ev, true);
    }, []);
    function updateMaskRef() {
        var input = innerRef.current;
        if (imask.current) {
            imask.current.updateOptions(maskOptions);
        }
        if (!imask.current && input) {
            imask.current = IMask(input, maskOptions);
            imask.current.on('accept', _onAccept);
        }
        if (imask.current && imask.current.value !== lastValue.current) {
            imask.current.value = lastValue.current;
            imask.current.alignCursor();
        }
    }
    function updateValue(value) {
        lastValue.current = value;
        var input = innerRef.current;
        var masked = imask.current;
        if (!(input && masked))
            return;
        masked.value = value;
        // updating value with the masked
        //   version (imask.value has a setter that triggers masking)
        input.value = masked.value;
        lastValue.current = masked.value;
    }
    React.useEffect(function () {
        updateMaskRef();
        return function () {
            var _a;
            (_a = imask.current) === null || _a === void 0 ? void 0 : _a.destroy();
            imask.current = null;
        };
    }, [mask]);
    React.useEffect(function () {
        updateValue(propValue);
    }, [propValue]);
    var eventHandlers = React.useMemo(function () {
        var _a;
        return _a = {
                onBlur: function (ev) {
                    var _a;
                    _onEvent(ev);
                    (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, ev);
                },
                onPaste: function (ev) {
                    var _a, _b;
                    lastValue.current = (_a = ev.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text');
                    if (ev.target) {
                        // @ts-ignore
                        ev.target.value = lastValue.current;
                    }
                    _onEvent(ev, true);
                    (_b = props.onPaste) === null || _b === void 0 ? void 0 : _b.call(props, ev);
                },
                onFocus: function (ev) {
                    var _a;
                    _onEvent(ev);
                    (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, ev);
                }
            },
            _a[KEY_PRESS_EVENT] = function (ev) {
                var _a;
                _onEvent(ev, true);
                (_a = props[KEY_PRESS_EVENT]) === null || _a === void 0 ? void 0 : _a.call(props, ev);
            },
            _a;
    }, []);
    return (React.createElement(Input, __assign({ placeholder: placeholder }, antdProps, eventHandlers, { onChange: function (ev) { return _onEvent(ev, true); }, value: value, ref: function handleInputMask(ref) {
            if (antdRef) {
                if (typeof antdRef == 'function') {
                    antdRef(ref);
                }
                else {
                    antdRef.current = ref;
                }
            }
            if (ref === null || ref === void 0 ? void 0 : ref.input) {
                innerRef.current = ref.input;
                if (!imask.current) {
                    updateMaskRef();
                }
            }
        } })));
});
function keyPressPropName() {
    if (typeof navigator !== 'undefined') {
        return navigator.userAgent.match(/Android/i)
            ? 'onBeforeInput'
            : 'onKeyPress';
    }
    return 'onKeyPress';
}
var KEY_PRESS_EVENT = keyPressPropName();
export default MaskedInput;
