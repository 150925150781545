import { internalRoleManager } from './internalRoleManager';
export class DefaultSyncedRoleManager extends internalRoleManager {
    synced() {
        return true;
    }
    addDomainMatchingFunc(fn) {
        return this.addDomainMatchingFuncInternal(fn);
    }
    addMatchingFunc(fn) {
        return this.addMatchingFuncInternal(fn);
    }
    addLink(name1, name2, ...domain) {
        return this.addLinkInternal(name1, name2, ...domain);
    }
    clear() {
        return this.clearInternal();
    }
    deleteLink(name1, name2, ...domain) {
        return this.deleteLinkInternal(name1, name2, ...domain);
    }
    getRoles(name, ...domain) {
        return this.getRolesInternal(name, ...domain);
    }
    getUsers(name, ...domain) {
        return this.getUsersInternal(name, ...domain);
    }
    hasLink(name1, name2, ...domain) {
        return this.hasLinkInternal(name1, name2, ...domain);
    }
    printRoles() {
        return this.printRolesInternal();
    }
}
