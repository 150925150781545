export class EnforceContext {
    constructor(rType, pType, eType, mType) {
        this.pType = pType;
        this.eType = eType;
        this.mType = mType;
        this.rType = rType;
    }
}
export class NewEnforceContext {
    constructor(index) {
        return new EnforceContext('r' + index, 'p' + index, 'e' + index, 'm' + index);
    }
}
