import { useState, useCallback } from 'react';
export var useModal = function (config) {
    var modalConfig = config || {};
    var _a = modalConfig.defaultVisible, defaultVisible = _a === void 0 ? false : _a;
    var _b = useState(defaultVisible), visible = _b[0], setVisible = _b[1];
    var show = useCallback(function () { return setVisible(true); }, [visible]);
    var close = useCallback(function () { return setVisible(false); }, [visible]);
    var modalProps = {
        visible: visible,
        onCancel: close,
    };
    return {
        visible: visible,
        show: show,
        close: close,
        modalProps: modalProps,
    };
};
