import { Helper } from './helper';
import { arrayEquals, policyArrayToString, policyStringToArray } from '../util';
/**
 * MemoryAdapter is the memory adapter for Casbin.
 * It can load policy from a string.
 */
export class MemoryAdapter {
    /**
     * MemoryAdapter is the constructor for MemoryAdapter.
     * @param policy - policy formatted as a CSV string, or policy array.
     */
    constructor(policy) {
        this.policies = [];
        if (!policy) {
            return;
        }
        if (typeof policy === 'string') {
            this.policies = policyStringToArray(policy);
        }
        else {
            this.policies = policy;
        }
    }
    /**
     * hasPolicy checks if specific policy exists in storage.
     */
    hasPolicy(policy) {
        return this.policies.some((prePolicy) => {
            return arrayEquals(prePolicy, policy);
        });
    }
    /**
     * loadPolicy loads data in adapter to model.
     * @param model
     */
    async loadPolicy(model) {
        this.policies.forEach((n) => {
            if (!n) {
                return;
            }
            Helper.loadPolicyLine(policyArrayToString(n), model);
        });
    }
    /**
     * savePolicy saves all policy rules to the storage.
     */
    async savePolicy(model) {
        throw new Error('not implemented');
    }
    /**
     * addPolicy adds a policy rule to the storage.
     */
    async addPolicy(sec, ptype, rule) {
        const policy = rule.slice();
        policy.unshift(ptype);
        if (!this.hasPolicy(rule)) {
            this.policies.push(policy);
        }
    }
    /**
     * removePolicy removes a policy rule from the storage.
     */
    async removePolicy(sec, ptype, rule) {
        const ruleClone = rule.slice();
        ruleClone.unshift(ptype);
        this.policies = this.policies.filter((r) => !arrayEquals(ruleClone, r));
    }
    /**
     * removeFilteredPolicy removes policy rules that match the filter from the storage.
     */
    async removeFilteredPolicy(sec, ptype, fieldIndex, ...fieldValues) {
        throw new Error('not implemented');
    }
    /**
     * addPolicies adds policy rules to the storage.
     */
    async addPolicies(sec, ptype, rules) {
        for (const rule of rules) {
            if (!this.hasPolicy(rule)) {
                await this.addPolicy(sec, ptype, rule);
            }
        }
    }
    /**
     * removePolicies removes policy rules from the storage.
     * This is part of the Auto-Save feature.
     */
    async removePolicies(sec, ptype, rules) {
        this.policies = this.policies.filter((rule) => {
            return !rules.some((deleteRule) => arrayEquals(deleteRule, rule));
        });
    }
}
