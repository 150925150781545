import { __extends } from "tslib";
import { getBucketEndpointPlugin } from "@aws-sdk/middleware-bucket-endpoint";
import { getFlexibleChecksumsPlugin } from "@aws-sdk/middleware-flexible-checksums";
import { getSerdePlugin } from "@aws-sdk/middleware-serde";
import { getSsecPlugin } from "@aws-sdk/middleware-ssec";
import { Command as $Command } from "@aws-sdk/smithy-client";
import { UploadPartOutput, UploadPartRequest } from "../models/models_1";
import { deserializeAws_restXmlUploadPartCommand, serializeAws_restXmlUploadPartCommand, } from "../protocols/Aws_restXml";
var UploadPartCommand = (function (_super) {
    __extends(UploadPartCommand, _super);
    function UploadPartCommand(input) {
        var _this = _super.call(this) || this;
        _this.input = input;
        return _this;
    }
    UploadPartCommand.prototype.resolveMiddleware = function (clientStack, configuration, options) {
        this.middlewareStack.use(getSerdePlugin(configuration, this.serialize, this.deserialize));
        this.middlewareStack.use(getSsecPlugin(configuration));
        this.middlewareStack.use(getBucketEndpointPlugin(configuration));
        this.middlewareStack.use(getFlexibleChecksumsPlugin(configuration, {
            input: this.input,
            requestAlgorithmMember: "ChecksumAlgorithm",
            requestChecksumRequired: false,
        }));
        var stack = clientStack.concat(this.middlewareStack);
        var logger = configuration.logger;
        var clientName = "S3Client";
        var commandName = "UploadPartCommand";
        var handlerExecutionContext = {
            logger: logger,
            clientName: clientName,
            commandName: commandName,
            inputFilterSensitiveLog: UploadPartRequest.filterSensitiveLog,
            outputFilterSensitiveLog: UploadPartOutput.filterSensitiveLog,
        };
        var requestHandler = configuration.requestHandler;
        return stack.resolve(function (request) {
            return requestHandler.handle(request.request, options || {});
        }, handlerExecutionContext);
    };
    UploadPartCommand.prototype.serialize = function (input, context) {
        return serializeAws_restXmlUploadPartCommand(input, context);
    };
    UploadPartCommand.prototype.deserialize = function (output, context) {
        return deserializeAws_restXmlUploadPartCommand(output, context);
    };
    return UploadPartCommand;
}($Command));
export { UploadPartCommand };
