// noinspection JSVoidFunctionReturnValueUsed
import { internalRoleManager } from './internalRoleManager';
export class DefaultRoleManager extends internalRoleManager {
    synced() {
        return false;
    }
    addDomainMatchingFunc(fn) {
        return Promise.resolve(this.addDomainMatchingFuncInternal(fn));
    }
    addMatchingFunc(fn) {
        return Promise.resolve(this.addMatchingFuncInternal(fn));
    }
    addLink(name1, name2, ...domain) {
        return Promise.resolve(this.addLinkInternal(name1, name2, ...domain));
    }
    deleteLink(name1, name2, ...domain) {
        return Promise.resolve(this.deleteLinkInternal(name1, name2, ...domain));
    }
    getRoles(name, ...domain) {
        return Promise.resolve(this.getRolesInternal(name, ...domain));
    }
    hasLink(name1, name2, ...domain) {
        return Promise.resolve(this.hasLinkInternal(name1, name2, ...domain));
    }
    clear() {
        return Promise.resolve(this.clearInternal());
    }
    getUsers(name, ...domain) {
        return Promise.resolve(this.getUsersInternal(name, ...domain));
    }
    printRoles() {
        return Promise.resolve(this.printRolesInternal());
    }
}
