import { Helper } from './helper';
import { MemoryAdapter } from './memoryAdapter';
import { policyArrayToString } from '../util';
export class Filter {
    constructor() {
        this.g = [];
        this.p = [];
    }
}
export class DefaultFilteredAdapter extends MemoryAdapter {
    constructor(policy) {
        super(policy);
        this.filtered = false;
    }
    // loadPolicy loads all policy rules from the storage.
    async loadPolicy(model) {
        this.filtered = false;
        await super.loadPolicy(model);
    }
    async loadFilteredPolicy(model, filter) {
        if (!filter) {
            await this.loadPolicy(model);
            return;
        }
        await this.loadFilteredPolicyFile(model, filter, Helper.loadPolicyLine);
        this.filtered = true;
    }
    async loadFilteredPolicyFile(model, filter, handler) {
        this.policies.forEach((n) => {
            const line = policyArrayToString(n);
            if (!line || DefaultFilteredAdapter.filterLine(line, filter)) {
                return;
            }
            handler(line, model);
        });
    }
    isFiltered() {
        return this.filtered;
    }
    async savePolicy(model) {
        if (this.filtered) {
            throw new Error('cannot save a filtered policy');
        }
        await super.savePolicy(model);
        return true;
    }
    static filterLine(line, filter) {
        if (!filter) {
            return false;
        }
        const p = line.split(',');
        if (p.length === 0) {
            return true;
        }
        let filterSlice = [];
        switch (p[0].trim()) {
            case 'p':
                filterSlice = filter.p;
                break;
            case 'g':
                filterSlice = filter.g;
                break;
        }
        return DefaultFilteredAdapter.filterWords(p, filterSlice);
    }
    static filterWords(line, filter) {
        if (line.length < filter.length + 1) {
            return true;
        }
        let skipLine = false;
        for (let i = 0; i < filter.length; i++) {
            if (filter[i] && filter[i] !== filter[i + 1]) {
                skipLine = true;
                break;
            }
        }
        return skipLine;
    }
}
